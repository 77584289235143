import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import { BaseSeoSection, SeoHeaders } from 'shared/components'

import HeroSection from '../components/landing/hero_section'
import ScalingWrapper from '../components/scaling_wrapper'
import Layout from '../layouts/landing'

const IndexPage = () => {
  return (
    <Layout>
      <SeoHeaders description='Renta en dólares: Potencia tu Patrimonio de manera segura e inteligente' />
      <HeroSection />
      <BaseSeoSection
        maxWidth='lg'
        seoTitle='Accede a rentabilidades superiores a través de herramientas financieras blockchain.'
        seoSubtitle={
          'En un mundo financiero en constante evolución, la tecnología blockchain ha emergido como una herramienta '
          + 'crucial para acceder a oportunidades de inversión innovadoras y rentables. Nuestra plataforma utiliza las '
          + 'últimas tecnologías financieras basadas en blockchain para ofrecerte una forma segura y transparente de '
          + 'invertir tu capital, permitiéndote aprovechar rendimientos superiores en mercados globales.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/section1.png'
              alt='Rentabilidades via blockchain'
              loading='eager'
              placeholder='none'
              width={350}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
      <BaseSeoSection
        reversed
        maxWidth='lg'
        seoTitle='Fondo de Dólar Digital.'
        seoSubtitle={
          'Nuestro fondo principal, centrado en el Dólar Digital, ofrece una plataforma estable y segura para '
          + 'aquellos que desean invertir en dólares sin la necesidad de manejar divisas físicas. Aprovechando '
          + 'la tecnología blockchain, este fondo convierte tus inversiones en pesos a dólares digitales, '
          + 'permitiéndote acceder a mercados internacionales y beneficiarte de rendimientos superiores. '
          + 'Es ideal para inversores que buscan estabilidad y rendimientos consistentes en una moneda fuerte.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/section2.png'
              alt='Fondo de dolar digital'
              loading='eager'
              placeholder='none'
              width={350}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
      <BaseSeoSection
        maxWidth='lg'
        seoTitle='Fondo sobre Ethereum (ETH).'
        seoSubtitle={
          'Explora el potencial de las criptomonedas con nuestro fondo dedicado a Ethereum. Este fondo invierte '
          + 'directamente en ETH, una de las principales criptomonedas del mundo, y además capitaliza en estrategias'
          + 'financieras innovadoras generando rentabilidad adicional. Es una excelente opción para aquellos que '
          + 'buscan diversificar en activos digitales con un alto potencial de apreciación a largo plazo.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/section3.png'
              alt='Fondo sobre Ethereum'
              loading='eager'
              placeholder='none'
              width={350}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
      <BaseSeoSection
        reversed
        maxWidth='lg'
        seoTitle='Fondo Especulativo sobre Empresas Financieras basadas en Blockchain.'
        seoSubtitle={
          'Para los inversores que buscan oportunidades de crecimiento dinámico, nuestro fondo especulativo se '
          + 'centra en las principales empresas financieras que utilizan tecnología blockchain. Este fondo está '
          + 'diseñado para capitalizar las fluctuaciones y el rápido desarrollo del sector financiero digital, '
          + 'ofreciendo la posibilidad de rendimientos excepcionales mediante inversiones en compañías innovadoras '
          + 'que están redefiniendo los servicios financieros globales. Cada uno de estos fondos está diseñado para '
          + 'aprovechar distintas facetas del mercado digital y blockchain, ofreciendo a los inversores una variedad '
          + 'de opciones según sus metas y tolerancia al riesgo.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/section4.png'
              alt='Fondo especulativo blockchain'
              loading='eager'
              placeholder='none'
              width={350}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
    </Layout>
  )
}

export default IndexPage
