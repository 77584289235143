import * as React from 'react'

import { Box } from '@mui/material'

type ScalingImageProps = {
  children: React.ReactNode
}

const ScalingWrapper = ({
  children,
}: ScalingImageProps) => {
  const ref = React.useRef()
  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    const currentRef = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.4 },
    )

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [])

  return (
    <Box
      ref={ref}
      sx={{
        width: 'fit-content',
        transition: 'transform 0.5s ease-out',
        transform: isVisible ? 'scale(1)' : 'scale(0.85)',
      }}
    >
      {children}
    </Box>
  )
}

export default ScalingWrapper
