import * as React from 'react'

import { Container } from '@mui/material'

import { LandingCtaButton, Subtitle, Title } from 'shared/components'
import { isLoggedIn } from 'shared/services'

const HeroSection = () => {
  return (
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'min(70vh, 800px)',
        gap: 4,
        px: 1,
        py: 3,
      }}
    >
      <Title
        mainTitle
        colorful
        fontWeight={800}
        textAlign='center'
      >
        Renta en dólares: Potencia tu Patrimonio de manera segura e inteligente
      </Title>
      <Subtitle
        mainTitle
        fontWeight={300}
        textAlign='center'
      >
        Transforma tus Pesos en Dólares Digitales:
        <br style={{ display: 'block', content: '""', height: '0.5em' }} />
        Invierte Localmente, Benefíciate Globalmente
      </Subtitle>
      <LandingCtaButton
        id='hero-cta'
        size='large'
        sx={{ px: 4 }}
        isLoggedIn={isLoggedIn}
      />
    </Container>
  )
}

export default HeroSection
